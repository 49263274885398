import React, {
  createContext,
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

interface Alert {
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
  duration?: number | null;
}
interface Snack extends Alert {
  id: number;
  open: boolean;
  onClose?(): void;
}
type Toast = Omit<Snack, 'id' | 'open' | 'position'>;
type Dispatch = (data: Alert) => void;

const AlertContext = createContext<Dispatch | undefined>(undefined);

const Snack = React.forwardRef<HTMLDivElement, Toast>((props, ref) => (
  <MuiAlert
    elevation={6}
    variant="filled"
    severity={props.type}
    onClose={props.onClose}
    ref={ref}
  >
    {props.message}
  </MuiAlert>
));

const AlertProvider: React.FC = ({ children }) => {
  const alertIndex = useRef(0);
  const [alerts, set] = useState<Snack[] | undefined>(undefined);

  const handleOpen = useCallback(
    ({ type = 'success', message = '', duration = 6000 }: Alert) => {
      set(prev => [
        ...(prev !== undefined ? prev : []),
        {
          id: alertIndex.current++,
          open: true,
          type,
          message,
          duration,
        },
      ]);
    },
    [],
  );
  const handleClose = (id: number, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    set(prev => (prev ? prev.filter(item => id !== item.id) : prev));
  };

  return (
    <>
      <AlertContext.Provider value={handleOpen}>
        {children}
      </AlertContext.Provider>
      {alerts && alerts.length > 0
        ? alerts.map((alert, index) => (
            <Snackbar
              key={alert.id}
              open={alert.open}
              autoHideDuration={alert.duration}
              onClose={(_, r) => handleClose(alert.id, r)}
              style={{
                bottom: 24 * (index + 1),
                right: 100,
                transform: `translateY(-${index * 100}%)`,
                transition: 'transform 0.3s ease-in-out',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Snack
                onClose={() => handleClose(alert.id)}
                type={alert.type}
                message={alert.message}
              />
            </Snackbar>
          ))
        : null}
    </>
  );
};

function useDisplayAlert(): Dispatch {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useDisplayAlert must be used within an AlertProvider');
  }
  return context;
}

export { AlertProvider, useDisplayAlert };
