/** @jsxImportSource @emotion/react */
import { ClassNames, css, jsx, keyframes } from '@emotion/react';
import InputBase from '@mui/material/InputBase';
import { useTheme, Theme } from '@mui/material/styles';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Flipped } from 'react-flip-toolkit';
import { getPin, verifyPin, AuthChannel } from '../../api/Login/login';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as Background } from '../../assets/NotLoggedInBackground.svg';

const fadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

const classes = {
  input: (theme: Theme) => ({
    fontSize: '16px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.common.black,
    borderRadius: '100px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '24px',
    paddingRight: '24px',
    '&:focus': {
      outline: 0,
      borderColor: theme.palette.primary.main,
    },
    '&::placeholder': {
      color: theme.palette.text.secondary,
    },
  }),
};

interface NotLoggedInProps {
  login: () => void;
  loginError: string;
  clearError: () => void;
}
function NotLoggedIn({ login, loginError, clearError }: NotLoggedInProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState('354');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pinNumber, setPinNumber] = useState('');
  const [pinToken, setPinToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const pinField = useRef<HTMLInputElement>();
  const [callRequested, setCallRequested] = useState(false);

  useEffect(() => {
    if (loginError) {
      setIsLoading(false);
      setCountryCode('354');
      setPhoneNumber('');
      setPinNumber('');
      setPinToken('');
      setErrorMessage('');
    }
  }, [loginError]);

  const getPinNumber = async () => {
    if (phoneNumber.length === 0 || Number.isNaN(parseInt(phoneNumber, 10))) {
      return;
    }

    setIsLoading(true);

    try {
      const token = await getPin(countryCode, phoneNumber);
      setPinToken(token);
      setErrorMessage('');
      clearError();
    } catch (error) {
      if (error.status === 429) {
        setErrorMessage(
          'Hold on, you tried too many times. Please wait for a few minutes before retrying.',
        );
      } else {
        setErrorMessage(
          'Oops, sorry! Looks like something went wrong, please try again.',
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const checkPin = async (pin: string) => {
    if (pin.length !== 4) {
      return;
    }

    setIsLoading(true);

    try {
      await verifyPin(pin, pinToken, countryCode, phoneNumber);
      login();
    } catch (error) {
      if (error.status === 400) {
        setErrorMessage('Oops! Looks like that PIN was incorrect.');
      } else {
        setErrorMessage(
          'Oops, sorry! Looks like something went wrong, please try again.',
        );
      }

      setIsLoading(false);
    }
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (pinToken) {
      if (pinNumber.length < 4) {
        setErrorMessage('Oops! Your pin is too short, it should be 4 numbers.');
        return;
      }
      if (pinNumber.length > 4) {
        setErrorMessage('Oops! Your pin is too long, it should be 4 numbers.');
        return;
      }
      checkPin(pinNumber);
    } else {
      getPinNumber();
    }
  };

  const requestPhoneCall = async () => {
    try {
      await getPin(countryCode, phoneNumber, AuthChannel.Call);
      setCallRequested(true);
    } catch (error) {
      if (error.status === 429) {
        setErrorMessage(
          'Hold on, you have tried too many times. Please wait for a few minutes before retrying.',
        );
      } else {
        setErrorMessage(
          'Oops, sorry! Looks like something went wrong, please try again.',
        );
      }
    }
  };

  useEffect(() => {
    // TODO: Yes, I'm too lazy to figure out how to do this without a setTimeout, please fix.
    setTimeout(() => {
      if (pinField.current) {
        pinField.current.focus();
      }
    });
  }, [pinToken]);

  return (
    <main
      css={css`
        display: flex;
        background-color: ${theme.palette.common.black};
      `}
    >
      <div
        css={css`
          flex: 1 0 50%;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
        `}
      >
        <form
          css={css`
            max-width: 320px;
            width: 100%;
            display: flex;
            flex-direction: column;
          `}
          onSubmit={handleSubmit}
        >
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-bottom: 80px;
            `}
          >
            <Flipped flipId="logo">
              <div>
                <Logo />
              </div>
            </Flipped>
          </div>

          {!pinToken ? (
            <Fragment>
              <label
                css={css`
                  font-size: 16px;
                  font-weight: bold;
                  margin-bottom: 16px;
                `}
                htmlFor="phoneNumber"
              >
                Phone Number
              </label>
              <div
                css={css`
                  display: flex;
                  margin-bottom: 24px;
                `}
              >
                <ClassNames>
                  {({ css }) => (
                    <InputBase
                      id="countryCode"
                      type="tel"
                      required
                      value={countryCode}
                      onChange={event => setCountryCode(event.target.value)}
                      autoComplete="false"
                      classes={{
                        root: css(classes.input(theme)),
                      }}
                      css={{
                        width: '100px',
                        marginRight: '8px',
                      }}
                    />
                  )}
                </ClassNames>
                <InputBase
                  id="phoneNumber"
                  type="tel"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  required
                  placeholder="766-5665"
                  value={phoneNumber}
                  autoComplete="false"
                  onChange={event => setPhoneNumber(event.target.value)}
                  css={classes.input}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <label
                css={css`
                  font-size: 16px;
                  font-weight: bold;
                  margin-bottom: 16px;
                `}
                htmlFor="pinCode"
              >
                Enter the Pin Code that has been sent to your phone
              </label>
              <ClassNames>
                {({ css }) => (
                  <InputBase
                    id="pinCode"
                    inputRef={pinField}
                    type="number"
                    required
                    disabled={isLoading}
                    onChange={event => setPinNumber(event.target.value)}
                    classes={{
                      root: css(classes.input(theme)),
                    }}
                    css={{
                      marginBottom: '24px',
                      textAlign: 'center',
                    }}
                  />
                )}
              </ClassNames>
              {callRequested ? (
                <label
                  css={css`
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 16px;
                  `}
                  htmlFor="pinCode"
                >
                  {
                    'You will receive a phone call shortly where we will tell you the pin code!'
                  }
                </label>
              ) : (
                <ClassNames>
                  {({ css }) => (
                    <button
                      css={css`
                        all: unset;
                        font-size: 16px;
                        margin-bottom: 16px;
                        cursor: pointer;
                      `}
                      type="button"
                      onClick={requestPhoneCall}
                    >
                      {"Didn't receive an SMS?"}
                    </button>
                  )}
                </ClassNames>
              )}
              {isLoading && <p>Loading...</p>}
            </Fragment>
          )}
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <button
              css={css`
                appearance: none;
                display: inline-block;
                text-align: center;
                text-decoration: none;
                border: 0;
                font-weight: bold;
                background-color: ${theme.palette.primary.main};
                border-radius: 100px;
                font-size: 16px;
                color: ${theme.palette.common.black};
                padding-left: 40px;
                padding-right: 40px;
                padding-top: 12px;
                padding-bottom: 12px;
                cursor: pointer;
              `}
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
          {errorMessage.length ? <p>{errorMessage}</p> : null}
          {loginError.length ? <p>{loginError}</p> : null}
        </form>
      </div>
      <div
        css={css`
          flex: 1 0 50%;
          width: 50%;
          background: linear-gradient(
            100.3deg,
            #1ce5be 7.77%,
            #00b1b2 28.78%,
            #007e95 51.12%,
            #004e69 73.47%,
            #1f2022 93.59%
          );
          height: 100vh;
          overflow: hidden;
          position: relative;
          animation: ${fadeAnimation};
          animation-duration: 1s;
          animation-fill-mode: forwards;

          &::after {
            display: block;
            content: '';
            width: 2519px;
            height: 2519px;
            border-radius: 100%;
            background-color: ${theme.palette.common.black};
            position: absolute;
            top: 50%;
            right: 80%;
            transform: translateY(-50%);
          }
        `}
      >
        <Background height="100vh" width="100%" />
      </div>
    </main>
  );
}

export default NotLoggedIn;
