import logdna from '@logdna/browser';

// Centralized LogDNA configuration
function setupLogDNA(): void {
  // Skip silently if no LogDNA key is provided.
  if (!process.env.REACT_APP_LOGDNA_INGESTION_KEY) {
    console.warn('REACT_APP_LOGDNA_INGESTION_KEY is not set');
    return;
  }

  logdna.init(process.env.REACT_APP_LOGDNA_INGESTION_KEY, {
    app: 'opp',
    env: process.env.NODE_ENV,
    console: true,
  });
}

export { setupLogDNA };
