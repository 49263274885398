export function onlyOnce<F extends (...args: any[]) => Promise<any>>(
  asyncFunction: F,
) {
  let promise: Promise<any> | null = null;
  return function (...args: any[]) {
    if (promise) {
      return promise;
    }
    promise = asyncFunction(args).finally(() => {
      promise = null;
    });
    return promise;
  } as F;
}
