import { getAuthorizationHeader } from '../graphql/token';

const getData = async (res: Response, parseJSON = true) => {
  let data = null;
  if (parseJSON) {
    try {
      data = await res.json();
    } catch {
      // returns data as null
    }
  } else {
    data = res;
  }
  if (!res.ok) {
    const error = new Error(
      (data && data.message) ||
        `Unexpected server error (${res.status} ${res.url})`,
    );
    throw error;
  }
  return data;
};

const safeFetch = async (
  url: string,
  { parseJSON = true, ...requestOptions }: RequestInit & { parseJSON: boolean },
) => {
  const fetchOptions: RequestInit = {
    ...requestOptions,
    headers: {
      Accept: 'application/json',
      'X-User-Agent': 'HoppDashboard',
      authorization: getAuthorizationHeader(),
      ...requestOptions.headers,
    },
  };
  const response = await fetch(url, fetchOptions);
  return getData(response, parseJSON);
};

type HoppClientOptions = {
  parseJSON: boolean;
};

/**
 * Call the Hopp API and handle errors.
 */
class HoppClient {
  post(
    url: string,
    body: Record<string, unknown>,
    options: HoppClientOptions = { parseJSON: true },
  ) {
    return safeFetch(`${process.env.REACT_APP_HOPP_API_URL}${url}`, {
      method: 'POST',
      body: JSON.stringify(body),
      ...options,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  get(url: string, options: HoppClientOptions = { parseJSON: true }) {
    return safeFetch(`${process.env.REACT_APP_HOPP_API_URL}${url}`, {
      method: 'GET',
      ...options,
    });
  }
  postImage(
    url: string,
    imageForm: FormData,
    options: HoppClientOptions = { parseJSON: true },
  ) {
    return safeFetch(`${process.env.REACT_APP_HOPP_API_URL}${url}`, {
      method: 'POST',
      body: imageForm,
      ...options,
    });
  }
}

export default new HoppClient();
