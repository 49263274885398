import HoppClient from '../HoppClient';
import { updateToken } from '../../graphql/token';
import { onlyOnce } from '../../utils/onlyOnce';

export enum AuthChannel {
  SMS = 'sms',
  Call = 'call',
}

export const getPin = async (
  countryCode: string,
  phoneNumber: string,
  channel: AuthChannel = AuthChannel.SMS,
): Promise<string> => {
  const { token } = await HoppClient.post(`/user/requestpin`, {
    phone: `+${countryCode}${phoneNumber}`,
    channel,
    client: 'admin',
  });
  return token;
};

export const verifyPin = async (
  pin: string,
  requestToken: string,
  countryCode: string,
  phoneNumber: string,
  client = 'admin',
): Promise<void> => {
  const { token, refreshToken } = await HoppClient.post(`/user/pin`, {
    pin,
    token: requestToken,
    phone: `+${countryCode}${phoneNumber}`,
    client,
  });
  if (token) {
    updateToken({ token, refreshToken });
  }
};

export const refresh = onlyOnce(
  async (oldRefreshToken: string): Promise<void> => {
    const { token, refreshToken } = await HoppClient.post(`/user/token`, {
      refreshToken: oldRefreshToken,
    });
    if (token) {
      updateToken({ token, refreshToken });
    }
  },
);
