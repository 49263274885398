import { AllWatcherEvents } from '../../types';

export const mergeAllWatcherEvents = {
  keyArgs: ['fleetId', 'vehicleId'],
  merge(existing = { watcherEvents: [] }, incoming: any) {
    return {
      watcherEvents: [...existing.watcherEvents, ...incoming.watcherEvents],
      pageInfo: incoming.pageInfo,
    };
  },
  read: (existing: AllWatcherEvents) =>
    existing && {
      ...existing,
      watcherEvents: existing.watcherEvents.slice(),
    },
};
